<template>
    <div>
        <nav-bar title="订单列表" url="-1"></nav-bar>

        <div class="tab">
            <van-tabs v-model="type" :color="config.main_color" class="tab" @change="change">
                <van-tab title="个人办理" name="2"></van-tab>
                <van-tab title="企业办理" name="1"></van-tab>
                <van-tab title="门票套票" name="3"></van-tab>
            </van-tabs>
        </div>

        <van-list v-model="loading" :finished="finish" finished-text="没有更多了" @load="get_list">
            <div class="order-list">
                <div class="order-list-content" @click="url('/order_detail?id=' + item.id)" v-for="(item,index) in list"
                    v-if="type!=='3'" :key="index">
                    <div class="order-name">
                        {{item.name}}
                        <span v-if="item.is_company == 1">[{{item.company.company_name}}]</span>
                    </div>
                    <div class="order-state">
                        <van-tag round type="danger" v-if="item.state==-1">订单取消</van-tag>
                        <van-tag round v-if="item.state==1">待支付</van-tag>
                        <van-tag round type="success" v-if="item.state==2">待审核</van-tag>
                        <van-tag round type="danger" v-if="item.state==4">审核不通过</van-tag>
                        <van-tag round type="warning" v-if="item.state==5">异常退款</van-tag>
                        <van-tag round v-if="item.state==6">待审核</van-tag>
                        <van-tag round type="danger" v-if="item.state==7">审核不通过</van-tag>
                        <van-tag round type="success" v-if="item.state==8">激活成功</van-tag>
                        <van-tag round type="warning" v-if="item.state==9">退款失败</van-tag>
                    </div>
                    <div class="order-sfz">身份证号：{{item.sfz}}</div>
                    <div class="order-tel">电话号码：{{item.tel}}</div>
                    <div class="order-time">下单时间：{{item.create_time}}</div>
                    <div class="order-icon">
                        <img src="@/static/image/user/1.png">
                    </div>
                </div>

                <div class="order-list-content" style="height: 150px;" v-if="type=='3'"
                    @click="url('/package_detail?id=' + item.id)" v-for="(item,index) in list" :key="index">
                    <div class="order-name">{{item.order_name}}[￥{{item.order_money}}]</div>
                    <div class="order-state">
                        <van-tag round type="danger" v-if="item.order_state==0">订单取消</van-tag>
                        <van-tag round v-if="item.order_state==1">待支付</van-tag>
                        <van-tag round type="success" v-if="item.order_state==2">支付成功</van-tag>
                        <van-tag round type="danger" v-if="item.order_state==3">部分退款</van-tag>
                        <van-tag round type="warning" v-if="item.order_state==4">全部退款</van-tag>
                        <van-tag round v-if="item.order_state==7">开始使用</van-tag>
                        <van-tag round type="success" v-if="item.order_state==8">激活成功</van-tag>
                    </div>
                    <div class="order-sfz">身份证号：{{item.order_sfz}}</div>
                    <div class="order-tel">电话号码：{{item.order_tel}}</div>
                    <div class="order-time">订单编号：{{item.order_no}}</div>
                    <div class="order-time" style="top: 115px;">下单时间：{{item.create_time}}</div>
                    <div class="order-icon">
                        <img src="@/static/image/user/1.png">
                    </div>
                </div>
            </div>
        </van-list>

        <div v-if="list.length == 0">
            <van-empty description="列表为空" />
        </div>
    </div>
</template>

<script>
    import { oauth } from "@/static/js/oauth";
    import { card_order_list } from '@/api/card.js';
    export default {
        name: "index",
        data() {
            return {
                loading: false,
                finish: false,
                list: [],
                type: 2, //2企业，2个人，3套票
                page: 1,
                count: 10,
            }
        },
        created() {
            oauth();
        },
        methods: {
            change() {
                this.list = [];
                this.page = 1;
                this.finish = false;
                this.get_list();
            },

            //获取订单列表
            get_list() {
                this.loading = true;
                card_order_list({
                    page: this.page,
                    count: this.count,
                    is_company: this.type,
                }).then(res => {
                    if (res.code == 1) {
                        this.loading = false;
                        this.list = this.list.concat(res.data.info);
                        this.finish = res.data.finish ? true : false;
                        this.page = res.data.next_page;
                    }
                });
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .tab {
        margin-top: 10px;
        font-family: 'FZCS';
    }

    .order-list {
        padding-top: 15px;
    }

    .order-list-content {
        height: 120px;
        width: calc(100% - 30px);
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        border: 1px solid #038866;
        overflow: hidden;
        color: var(--main-color);
    }

    .order-list-content .order-name {
        font-weight: 900;
        position: absolute;
        font-size: 16px;
        left: 0;
        top: 0;
        color: var(--main-color);
        background: linear-gradient(to right, #FFFFFF, #E9FFF8);
        width: calc(100% - 20px);
        height: 30px;
        line-height: 30px;
        padding: 10px;
        font-family: 'fzcs';
    }

    .order-list-content .order-state {
        font-weight: 900;
        position: absolute;
        font-size: 16px;
        right: 10px;
        top: 10px;
        color: var(--main-color);
    }


    .order-list-content .order-sfz {
        font-weight: 200;
        position: absolute;
        font-size: 13px;
        left: 10px;
        top: 40px;
        color: var(--main-color);
    }

    .order-list-content .order-tel {
        font-weight: 200;
        position: absolute;
        font-size: 13px;
        left: 10px;
        top: 65px;
        color: var(--main-color);
    }


    .order-list-content .order-time {
        font-weight: 200;
        position: absolute;
        font-size: 13px;
        left: 10px;
        top: 90px;
        color: var(--main-color);
    }

    .order-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 45px;
    }

    .order-icon img {
        width: 100%;
    }
</style>