import request from '@/utils/request'
export function card_cate(data) {
    return request({url: 'card_cate',data});
}
export function card_sale(data) {
    return request({url: 'card_sale',data});
}
export function card_person_submit(data) {
    return request({url: 'card_person_submit',data});
}
export function card_pay_submit(data) {
    return request({url: 'card_pay_submit',data});
}
export function card_pay_success(data) {
    return request({url: 'card_pay_success',data});
}
export function card_pay_cancel(data) {
    return request({url: 'card_pay_cancel',data});
}
export function card_sfz(data) {
    return request({url: 'card_sfz',data});
}
export function card_book(data) {
    return request({url: 'card_book',data});
}
export function card_order_detail(data) {
    return request({url: 'card_order_detail',data});
}
export function card_order_list(data) {
    return request({url: 'card_order_list',data});
}